import PropTypes from 'prop-types';
import React from 'react';

import theme from '../../../styles/config/theme';

import ButtonCloseStyled from './ButtonClose.style';

const propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  crossColor: PropTypes.string,
  crossWidth: PropTypes.string,
  crossThickness: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

const defaultProps = {
  className: '',
  width: '3rem',
  height: '3rem',
  backgroundColor: theme.COLOR_BRAND_PRIMARY_DARK,
  crossColor: theme.COLOR_WHITE,
  crossWidth: '45%',
  crossThickness: '0.15rem',
  children: null,
  label: 'close',
  'data-testid': null,
};

const ButtonClose = ({
  className,
  width,
  height,
  backgroundColor,
  crossColor,
  crossWidth,
  crossThickness,
  label,
  onClick,
  children,
  ...props
}) => (
  <ButtonCloseStyled
    className={className}
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    crossColor={crossColor}
    crossWidth={crossWidth}
    crossThickness={crossThickness}
    onClick={onClick}
    data-testid={props['data-testid']}
  >
    {children || label}
  </ButtonCloseStyled>
);

ButtonClose.propTypes = propTypes;
ButtonClose.defaultProps = defaultProps;

export default ButtonClose;
