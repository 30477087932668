import styled from 'styled-components';

import Button from './Button';

export default styled(Button)`
  cursor: pointer;
  display: inline-block;
  border: 0;
  color: ${({ theme, color }) => color || theme.COLOR_BRAND_PRIMARY};
  line-height: 1.5;
  min-height: 0;
  padding: 0;
  outline: none;

  i {
    margin-left: 0.25rem;
  }

  &:disabled {
    text-decoration: none;
    color: ${({ theme }) => theme.COLOR_GRAY};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:not(:disabled)&:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;
