import gql from 'graphql-tag';

export default gql`
  query LayoutConfig {
    layout @rest(
      type: "LayoutConfig",
      path: "configuration/headerfooter"
    ) {
      css
      header {
        headerConfiguration {
          logoUrl
          logoUrlLink
          searchPath
        }
        headerLinks {
          appendRedirectQuery
          href
          logOutBeforeRedirect
          showButton
          showInMobileMenuBelow
          showWhenLoggedIn
          showWhenLoggedOut
          target
          text
          title
        }
        headerMenus {
          text
          url
        }
      }
      footer {
        footerConfiguration {
          logoUrl
          logoUrlLink
        }
        sections {
          heading
          content
        }
        footerLinks {
          href
          title
          target
          text
        }
      }
    }
  }
`;
