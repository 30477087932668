import styled, { css } from 'styled-components';

import Icon from '../Icon/Icon';

import { above } from '../../../styles/libs';

function isAligned(align) {
  return ['left', 'right'].contains(align);
}

const margin = {
  left: 'right',
  right: 'left',
};

export default styled(Icon)`
  margin-right: 0.5rem;

  ${({ align }) => (isAligned && css`margin-${margin[align]}: auto;`)};

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: 1.125rem;
  `}
`;
