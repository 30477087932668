import React from 'react';
import styled from 'styled-components';

import ButtonLink from './ButtonLink';

const ButtonLinkProminent = styled((props) => (
  <ButtonLink size="small" textDecoration="underline" textTransform="uppercase" noPadding {...props} />
))`
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
`;

export default ButtonLinkProminent;
