import styled from 'styled-components';

import theme from '../../../styles/config/theme';

import Button from './Button';

const ButtonInfo = styled(Button)`
  border: 0;
  color: ${({ color, lightTheme }) => color || (lightTheme ? theme.COLOR_WHITE : theme.COLOR_BRAND_PRIMARY)};
  font-size: ${({ fontSize }) => fontSize};
  height: ${({ fontSize }) => fontSize};
  line-height: 1;
  min-height: auto;
`;

ButtonInfo.defaultProps = {
  fontSize: '1rem',
};

export default ButtonInfo;
