import path from 'path';

const assetPath = './static/fonts/';

export default function FontFace(
  {
    name = '',
    src = '',
    fontWeight = 'normal',
    fontStyle = 'normal',
  },
) {
  const url = path.join(assetPath, src);

  return `
    @font-face{
      font-family: "${name}";
      src: url(${`${url}.eot`});
      src: url(${`${url}.eot`}?#iefix) format("embedded-opentype"),
        url(${`${url}.woff`}) format("woff"),
        url(${`${url}.ttf`}) format("truetype"),
        url(${`${url}.svg`}#${name}) format("svg");

      font-style: ${fontStyle};
      font-weight: ${fontWeight};
    }
  `;
}
