import styled from 'styled-components';

export default styled.button`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  text-indent: -9999vw;
  border: 0;
  outline: 0;
  margin: auto 1.25rem;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${props => props.crossColor};
    width: ${props => props.crossWidth};
    height: ${props => props.crossThickness};
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
