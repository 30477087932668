import styled from 'styled-components';

import Button from './Button';

export default styled(Button)`
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.COLOR_GRAY};
  }

  &:not(:disabled)&:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
