import React from 'react';
import PropTypes from 'prop-types';

import StyledButtonIcon from './ButtonIcon.style';

const defaultProps = {
  align: '',
  size: 'medium',
  color: '',
};

const propTypes = {
  align: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
};

const sizes = {
  small: '0.875rem',
  medium: '1rem',
  large: '1.5rem',
  color: '',
};

function ButtonIcon(props) {
  const iconProps = {
    icon: props.icon,
    align: props.align,
    size: sizes[props.size],
    color: props.color,
  };

  return (
    <StyledButtonIcon {...iconProps} />
  );
}

ButtonIcon.defaultProps = defaultProps;
ButtonIcon.propTypes = propTypes;

export default ButtonIcon;
