import styled, { css } from 'styled-components';

import Button from './Button';

export const ButtonBorderedStyle = css`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};

  ${({ marginRight }) => marginRight && css`
    margin-right: 1rem;
  `}

  ${({ marginTop }) => marginTop && css`
    margin-top  : 1rem;
  `}

  &:disabled {
    border-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
    color: ${({ theme }) => theme.COLOR_GRAY};
  }
  ${({ disabled, theme }) => disabled && css`
    border-color: ${() => theme.COLOR_GRAY_LIGHT};
    color: ${() => theme.COLOR_GRAY};
  `}

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
      color: ${({ theme }) => theme.COLOR_WHITE};
    }
  }
`;

const ButtonBordered = styled(Button)`
  ${ButtonBorderedStyle}
`;

export default ButtonBordered;
